section {
  padding: 120px 0;
}
/* .padding-half */
.siteWrapper {
  padding: 0 16px;
  width: 960px;
  margin: 0 auto;
}

.loaderHide {
  width: 100vw;
  height: 100vh;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  justify-items: center;
  align-items: center;
  opacity: 1;
  transition: all 0.5 ease-in;
  pointer-events: none;
  color: var(--white);
  background: red;
}

/* nav styling */
.header {
  position: sticky;
  top: 0;
}

nav {
  display: flex;
  background-color: var(--background);
  padding: 36px 0;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.logo {
  font-size: var(--text-m);
  font-weight: var(--bold);
  color: var(--white);
}
.navBtn {
  color: var(--white);
  font-family: var(--primary-font);
  font-size: var(--text-s);
  font-weight: var(--semi-bold);
  padding: 0;
  background-color: var(--background);
  border: none;
  cursor: pointer;
}
.navBtn:hover {
  color: var(--accent);
}

/* hero section */
.heroContainer {
  margin: auto;
  width: 640px;
  text-align: center;
}

/* category styling */
.categoryContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 32px;
}
.category,
.categoryActive {
  padding: 4px 16px;
  border: 1px;
  border-style: solid;
  border-color: var(--grey);
  border-radius: 40px;
  box-sizing: border-box;
  color: var(--white-80);
  font-size: var(--text-s);
  font-weight: var(--medium);
  background-color: var(--black);
  width: fit-content;
  margin: 0 16px 16px 0;
  cursor: pointer;
}
.categoryActive {
  color: var(--black);
  background-color: var(--accent);
  border-color: var(--accent);
}

/* card styling */
.card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 18px;
  background: var(--card-bg);
  border: 1px;
  border-style: solid;
  border-color: var(--dark-grey);
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 28px;
}
.card h2 span {
  opacity: 0;
}
.card:hover {
  border-color: var(--accent);
}
.card:hover h2 span {
  opacity: 1;
  transition: ease;
}
.card1stRow {
  margin-bottom: 12px;
}
.imgSmall {
  margin-left: 12px;
}

/* form styling */
.formPopup {
  position: absolute;
  top: 0px;
  left: 0px;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
}

.formPopup > div:nth-child(1) {
  width: 460px;
  padding: 32px;
  background-color: var(--black);
  border: 1px solid var(--dark-grey);
  border-radius: 8px;
  margin: auto;
}
.formTopContainer {
  border-bottom: 1px solid var(--dark-grey);
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.form1stRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.formTitle {
  font-size: var(--text-l);
}
.formBottomContainer {
  display: flex;
}
.formBottomContainer input {
  width: calc(460px - 32px);
}
.btnContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.closeBtn {
  padding: 0;
  border: none;
  outline: none;
  background-color: var(--black);
  cursor: pointer;
}
.hide {
  display: none !important;
}


/* Product Hunt Badge */
.productHuntBadge{

    display: flex;
    justify-content: center;
    margin: 120px auto 0 auto;
}

/* Footer styling */
footer {
  width: 100%;
  padding: 16px 0;
  background-color: var(--card-bg);
}
.footerContainer {
  display: flex;
  justify-content: space-between;
}
.footerLeft,
.copyright {
  color: var(--white-80) !important;
}
.footerLink {
  margin-left: 16px;
  color: var(--white-40);
}
.footerLink:hover {
  color: var(--accent);
}

@media only screen and (max-width: 1400px) {
  section {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 800px) {
  .heroContainer {
    width: 600px;
  }
}

@media only screen and (max-width: 600px) {
  body {
    scrollbar-width: none;
  }
  img{
    height: 12px;
    width: auto;
  }
  .imgSmall {
    margin-left: 6px;
  }
  section {
    padding: 56px 0;
    /* min-height: calc(100vh- 340px); */
    box-sizing: border-box;
  }
  .siteWrapper {
    width: auto;
  }
  nav {
    padding: 24px 0;
  }

  .heroContainer {
    max-width: 320px;
  }
  .categoryContainer {
    margin-bottom: 12px;
  }
  .category,
.categoryActive {
  margin: 0 8px 12px 0;
}
  .card {
    margin-bottom: 16px;
  }
  .formPopup > div:nth-child(1) {
    width: 80vw;
    padding: 16px;
  }
  .formTopContainer {
    padding-bottom: 12px;
    margin-bottom: 16px;
  }
  .formTitle {
    font-size: var(--text-m-l);
  }
  .formBottomContainer input {
    width: calc(80vw - 32px);
  }
  .btnContainer {
    align-items: center;
  }

  .productHuntBadge{
    margin-top: 60px;
  }

  .footerContainer {
    flex-direction: column-reverse;
    align-items: center;
  }
  .footerLeft {
    margin-top: 8px;
  }
  .footerLink {
    margin: 0 8px;
  }
}

:root {
  --primary: #fff;
  --dark-grey: #474747;
  --grey: #86acac;
  --white: #f3f3f3;
  --white-80: #f3f3f3cc;
  --white-40: #f3f3f366;
  --background: #010101;
  --card-bg: #131313;
  --black: #131313;
  --accent: #bcfd2e;

  // --text-xl: 3rem;
  // --text-l: 2.25rem;
  // --text-m: 1.5rem;
  // --text-s: 1.125rem;
  // --text-para: 1rem;

  // --text-m-xl: 1.875rem;
  // --text-m-l: 1.5rem;
  // --text-m-m: 1rem;
  // --text-m-s: 0.875rem;
  // --text-m-para: 1rem;

  --text-xl: 48px;
  --text-l: 36px;
  --text-m: 24px;
  --text-s: 18px;
  --text-para: 16px;

  --text-m-xl: 28px;
  --text-m-l: 24px;
  --text-m-m: 16px;
  --text-m-s: 12px;
  --text-m-para: 16px;

  --primary-font: "Manrope";
  --secondary-font: 'Nunito Sans';
  --tertiary-font: 'Roboto';

  --bold: 700;
  --semi-bold: 600;
  --medium: 500;
  --regular: 400;
}

body {
  background-color: var(--background);
  font-family: var(--primary-font), var(--secondary-font), var(--tertiary-font), sans-serif  !important ;
  color: var(--primary);
}

.appContent {
  min-height: 100vh;
}
h1,
h2,
h3,
a,
text,
p {
  margin: 0;
}
h1 {
  font-size: var(--text-xl);
  color: var(--white);
  font-weight: var(--bold);
}
h2 {
  font-size: var(--text-m);
  color: var(--white);
  font-weight: var(--semi-bold);
}
h3 {
  font-size: var(--text-para);
  color: var(--grey);
  font-weight: var(--medium);
}

a {
  text-decoration: none;
  color: var(--accent);
}

label {
  font-size: var(--text-para);
  font-weight: var(--semi-bold);
  color: var(--white-80);
}
input {
  font-size: var(--text-para);
  font-weight: var(--regular);
  color: var(--white-80);
  border: 1px solid var(--white-40);
  border-radius: 4px;
  padding: 12px 16px;
  background-color: var(--black);
  margin: 8px 0 12px 0;
  width: 100%;

  outline: none;
}
input:hover,
:focus {
  border-color: var(--accent);
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--white-40);
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--white-40);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--white-40);
}

.btn {
  padding: 12px 48px;
  border: 1px solid var(--accent);
  box-sizing: border-box;
  border-radius: 4px;
  background-color: var(--accent);
  color: var(--black);
  font-family: var(--primary-font);
  font-size: var(--text-s);
  font-weight: var(--semi-bold);
}
.btn:hover {
  background-color: var(--black);
  color: var(--white);
}
.margin8 {
  margin: 32px 0 8px 0;
}

@media only screen and (max-width: 600px) {
  /* width */
  ::-webkit-scrollbar {
    width: 0px;
  }

  h1 {
    font-size: var(--text-m-xl);
  }
  h2 {
    font-size: var(--text-m-m);
  }
  h3 {
    font-size: var(--text-m-para);
  }
  label {
    font-size: var(--text-m-para);
  }
  input {
    font-size: var(--text-m-para);
    border-radius: 4px;
    padding: 12px 16px;
    margin: 8px 0 12px 0;
    width: 100%;
  }
  .btn {
    width: 100%;
    padding: 12px 48px;
    border-radius: 4px;
    margin-top: 16px;

    font-size: var(--text-m-m);
    font-weight: var(--semi-bold);
  }
  .imgSmall{
    height: 12px;
    width: auto;
  }
}

@media only screen and (max-width: 400px) {
  h1 {
    font-size: var(--text-m-l);
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 8px;
  background: hsl(0%, 0%, 28%, 0.2);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: hsl(0%, 0%, 28%, 0.4);
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  // background: hsl(0%, 0%, 28%, 0.7);
  background: linear-gradient(var(--accent), hsl(0%, 0%, 28%, 0.7),var(--accent));
}
